<template>
  <div class="container-main">
    <div class="title-vdo-01">শর্ত ও নিয়মাবলী</div>

    <div class="abt-us">
      <p>
        হুল্লোড় ওয়েবসাইটের সদস্য হিসাবে আপনার কিছু দায়িত্ব ও অধিকার রয়েছে।
        আমাদের শর্ত ও নিয়মাবলীর মাধ্যমে আপনি আপনার স্বার্থ রক্ষা করে আমাদের
        সেবাগুলো ব্যবহার করতে পারবেন।
      </p>
      <p>
        <label class="lblbold">আপনার কিছু উল্লেখযোগ্য অধিকারঃ</label><br />
        আমাদের কাছে আপনার প্রদত্ত সকল তথ্য আপনি পুনরুদ্ধার করতে পারেন, পরিবর্তন
        করতে পারেন অথবা পুরোপুরি মুছে ফেলতে পারেন।<br />
        আমরা আপনাকে কখনই অপ্রয়োজনীয় কোনো ইমেইল পাঠাব না।<br />
        আপনার দেওয়া ব্যক্তিগত তথ্যগুলো শুধুমাত্র হুল্লোড় ও এর সহযোগী
        প্রতিষ্ঠানসমূহ ব্যবহার করবে।
      </p>
      <p>
        <label class="lblbold">আপনার কিছু গুরুত্বপূর্ণ দায়িত্বঃ</label><br />
        “হুল্লোড়” ওয়েবসাইট ব্যবহার করে বেআইনি কিংবা অন্য সদস্যদের অধিকার লঙ্ঘন
        করে এমন কোনো কাজ আপনি করতে পারবেন না।<br />
        এই ওয়েবসাইট সম্পর্কে আপনার কোনো প্রশ্ন থাকলে অনুগ্রহ করে আমাদের সঙ্গে
        যোগাযোগ করুন ব্যবহারের নিয়মাবলী<br />
        https://www.hullor.io/ এই ওয়েবসাইট ব্যবহারের জন্য নিম্নলিখিত শর্ত ও
        নিয়মাবলীগুলো মনোযোগ দিয়ে পড়ুন।
      </p>
      <p>
        <label class="lblbold">লক্ষ করুনঃ</label><br />
        “হুল্লোড়” ওয়েবসাইট ব্যবহার করে বেআইনি কিংবা অন্য সদস্যদের অধিকার লঙ্ঘন
        করে এমন কোনো কাজ আপনি করতে পারবেন না। এই ওয়েবসাইট সম্পর্কে আপনার কোনো
        প্রশ্ন থাকলে অনুগ্রহ করে আমাদের সঙ্গে যোগাযোগ করুন ব্যবহারের নিয়মাবলী
        https://www.hullor.io/ এই ওয়েবসাইট ব্যবহারের জন্য নিম্নলিখিত শর্ত ও
        নিয়মাবলীগুলো মনোযোগ দিয়ে পড়ুন।<br />
        আপনি যেসব কাজ করতে পারবেন<br />
        এই ওয়েবসাইটের সেবাগুলো শুধুমাত্র আপনার ব্যক্তিগত প্রয়োজনে ব্যবহার করতে
        পারবেন। সর্বসাধারণের ব্যবহারের জন্য অথবা বাণিজ্যিক উদ্দেশে কোনোভাবেই
        সেগুলো ব্যবহার করতে পারবেন না।
      </p>
      <p>
        <label class="lblbold">আপনি যেসব কাজ করতে পারবেন নাঃ </label><br />
        ১। আপনি এই ওয়েবসাইটের কোনো বিষয়বস্তু সমন্বয় বা পরিবর্তন করতে পারবেন
        না এবং কোনো বিষয়বস্তু অনুকরণ বা নকল করে কোনো কাজ করতে পারবেন না। বিশেষ
        কোনো কাজে এই ওয়েবসাইটের কোনো বিষয়বস্তু ব্যবহারের জন্য অবশ্যই হুল্লোড়
        এর লিখিত অনুমতি নিতে হবে। এজন্য আমাদের সঙ্গে যোগাযোগ করুন<br />

        ২। এই ওয়েবসাইট বা এর কোনো উপকরণ বেআইনী কোনো কাজে ব্যবহার করা যাবে না।
        এছাড়া ওয়েবসাইটের অন্য কোনো সদস্যের অধিকার ক্ষুণ্ন করা, তাদের কাজে বাধা
        দেওয়া, কাউকে হয়রানি বা বিরক্ত করা, অশ্লীল বা অপরাধমূলক কোনো বিষয়
        ওয়েবসাইটে প্রকাশ করা নিষিদ্ধ।<br />

        ৩। কোনো বাণিজ্যিক কাজে ব্যবহার করার জন্য আমাদের ওয়েবসাইটের কোন তথ্য
        প্রিন্ট করতে পারবেন না।<br />
        দাবী পরিত্যাগ ও দায়িত্বের সীমাবদ্ধতা<br />
        হুল্লোড় অথবা এর সাথে সম্পর্কিত ওয়েবসাইটের উপাদানগুলো, যেমনঃ তথ্য, ইমেজ,
        ছবি, লোগো এবং আইকনগুলো এবং তাদের বা তাদের তৃতীয় পক্ষের পণ্য ও
        সার্ভিসগুলো কোনো প্রকার পরিবর্তন ছাড়াই এবং যে অবস্থায় পাওয়া যায়,
        হুবহু সেভাবেই ব্যবহার করা হয়। সেগুলো ব্যবহারের মধ্যে কোনো প্রতিনিধিত্ব
        থাকে না এবং প্রত্যক্ষ বা পরোক্ষভাবে আইনগত কোনো অনুমতির প্রতিশ্রুতি থাকে
        না। পাশাপাশি ব্যবহৃত বিষয়বস্তুগুলোর মানগত সন্তুষ্টি, কোনো নির্দিষ্ট
        বিষয়ের যোগ্যতা, কোনো শর্ত বা নিয়ম পালন, সামঞ্জস্যতা, নিরাপত্তা এবং
        যথাযথতার বিষয়ে কোনো প্রকার নিশ্চয়তা, প্রতিশ্রুতি বা ওয়ারেন্টি দেওয়া
        হয় না।<br />

        কোনো অবস্থার পরিপ্রেক্ষিতেই হুল্লোড় নিম্নলিখিত কোনো প্রকার ক্ষতি বা
        দূর্ঘটনার দায় গ্রহণ করবে না (পূর্বে ধারণাকৃত ক্ষতি, অবশ্যম্ভাবী ক্ষতি
        বা জানা ক্ষতি বা অন্য যেকোনো প্রকার ক্ষতি):<br />
        (ক) তথ্যের ক্ষতি<br />
        (খ) আয়ের বা প্রত্যাশিত লাভের ক্ষতি<br />
        (গ) ব্যবসার ক্ষতি<br />
        (ঘ) সুযোগের ক্ষতি<br />
        (ঙ) সুনাম বা সুখ্যাতির ক্ষতি<br />
        (চ) তৃতীয় পক্ষের মাধ্যমে সংঘটিত কোনো ক্ষতি<br />
        (ছ) কোনো প্রক্রিয়ার প্রতি মনোযোগহীনতার কারণে ঘুড়ি ব্যবহারের ফলে সৃষ্ট
        কোনো পরোক্ষ, গুরুত্ত্বপূর্ণ, বিশেষ বা আদর্শিক ক্ষতি<br />
        হুল্লোড় এমন কোনো নিশ্চয়তা, প্রতিশ্রুতি বা ওয়ারেন্টি দিতে পারে না যে,
        এই ওয়েবসাইটের কার্যক্রম বিনা বাধায় এবং কোনো প্রকার সমস্যা ছাড়াই
        অবিরাম চলবে, সহজেই যেকোনো সমস্যার সমাধান হয়ে যাবে অথবা এই ওয়েবসাইট বা
        সার্ভার পুরোপুরি ভাইরাস ও বাগমুক্ত থাকবে।<br />
        প্রদায়ক বা অংশগ্রহণকারী আমাদের অনুরোধের ভিত্তিতে ঘুরি ওয়েবসাইটে আপনি
        কোনোকিছু (যেমন- লেখা, ছবি, রেখাচিত্র, ভিডিও বা অডিও) প্রদান করতে সম্মত
        হলে, সেগুলো আপনি স্বত্ত্ব ত্যাগ করে, শর্তহীন ও চিরস্থায়ীভাবে ব্যবহার
        করার জন্য হুল্লোড় কে অনুমতি দিচ্ছেন বলে ধরে নেওয়া হবে। আপনার প্রদানকৃত
        বিষয়গুলো হুল্লোড় হুবহু অনুকরণ, পরিবর্তন, নিয়ন্ত্রণ, প্রকাশ ও অনুবাদ
        করতে পারবে। একই সাথে হুল্লোড় সেগুলো ব্যবহার করে ভিন্ন কোনো কাজ করার
        পাশাপাশি সরবরাহ, প্রদর্শন এবং জনসাধারণের জন্য সহজলভ্য করে তুলতে
        পারবে।<br />

        হুল্লোড় এ আপনার প্রদানকৃত উপাদানগুলো যেসব শর্তাবলীর মাধ্যমে আমরা গ্রহণ
        করে থাকিঃ<br />

        প্রদান করা উপাদানগুলো আপনার নিজের করা কাজ এবং উপাদানগুলো উপরে উল্লেখিত
        বিভিন্ন কাজে হুল্লোড় কে ব্যবহার করতে দেওয়ার অধিকার আপনার রয়েছে।<br />

        ১। উপাদানগুলো মানহানিকর নয়।<br />
        ২। উপাদানগুলো বেআইনী নয়।<br />
        যে দেশের ব্যবহারকারীদের জন্য ওয়েবসাইট ব্যবহারের এই নিয়মাবলী প্রযোজ্য,
        তার কোনো অংশ যদি সেই দেশের আইন অনুযায়ী অবৈধ, অপ্রয়োজনীয় বা প্রয়োগের
        অযোগ্য বলে চিহ্নিত হয়, তাহলে যথাযথ আইনগত নির্দেশনা সাপেক্ষে সেই অংশটি
        ব্যবহারের নিয়মাবলী থেকে বাদ দেওয়া হবে। তবে আইনগত আপত্তির বাইরে
        ব্যবহারের নিয়মাবলীর অন্য সকল অংশ আগের মতোই বহাল থাকবে এবং ব্যবহারকারীরা
        তা মেনে চলতে বাধ্য থাকবেন।<br />
        এই ওয়েবসাইট ব্যবহারের মাধ্যমে আপনি আইনত সকল নিয়মাবলী মেনে চলার
        অঙ্গীকার করছেন এবং ওয়েবসাইটটি প্রথমবারের মতো ব্যবহার করার সাথে সাথেই
        এটি কার্যকর বলে ধরা হবে।<br />

        নিয়মাবলী স্পষ্টভাবে বোঝার জন্য কিংবা সন্দেহ দূর করার জন্য হুল্লোড় যেকোন
        সময়ে নিয়মাবলীর যেকোন অংশ পরিবর্তন করে তা প্রকাশ করতে পারে। এ ধরনের কোনো
        পরিবর্তন সম্পর্কে জানার জন্য ব্যবহারের নিয়মাবলীর দিকে নিয়মিতভাবে লক্ষ
        রাখুন। নিয়মাবলীতে কোনো ধরনের পরিবর্তনের পর আপনি যদি ওয়েবসাইটটি আগের
        মতোই ব্যবহার করতে থাকেন তাহলে আপনি আইনত এই পরিবর্তন বা সংযোজনের সঙ্গে
        একমত বলে ধরে নেওয়া হবে।
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermCondition"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-main {
  display: block;
  margin: 0 auto;
}

.title-vdo-01 {
  padding: 15px 15px 10px 15px;
  font-family: inherit;
  font-weight: 600;
  font-size: 24px;
  color: aliceblue;
  line-height: 1.2;
  /* background: #232f3e; */
}

.abt-us {
  margin: 5px 15px 25px 15px;
  display: block;
  padding-bottom: 40px;
}

.abt-us p {
  font-size: 14px;
  font-family: inherit;
  font-weight: 400;
  color: aliceblue;
  line-height: 2;
  margin-bottom: 20px;
}
</style>
